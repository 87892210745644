import { defineStore } from 'pinia'
import api from '../api'
import { usePosStore } from './pos'
import { useAdminStore } from './admin'

export const useAppStore = defineStore('App', {
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'MyApp',
        storage: localStorage,
      },
    ],
  },

  state: () => ({
    mode: 'GUEST', // guest, register, login, boarding, neutral, idle , POS, admin,
    pageIsLoading: false,
    isSomethingLoading: {
      form: false,
      transaction_listing: false,
      checkin: false,
    },
    places: [],
    notifications: [],
    platform: getOS(), //Capacitor.getPlatform(),
    isOnline: true,
    started_at: new Date().getTime(),
    updateIsAvailable: false,
  }),

  actions: {
    setLoading(payload, loadingType) {
      if (loadingType !== undefined) {
        this.isSomethingLoading[loadingType] = payload
      } else {
        this.pageIsLoading = payload
      }
    },

    changeMode(payload) {
      const posStore = usePosStore()
      const adminStore = useAdminStore()
      this.mode = payload.mode

      if (['GUEST', 'POS', 'ADMIN'].includes(payload.mode)) {
        if (payload.mode === 'POS') {
          posStore.$patch({
            place_id: payload.place_id,
            place: payload.place,
          })
        } else if (payload.mode === 'ADMIN') {
          console.log(payload)
          adminStore.$patch({
            place_id: payload.place_id,
            place: payload.place,
          })
        }
      } else {
        // reset app
      }
    },

    async getAllPlaces() {
      try {
        this.places = await api.getModePlaces()
        return true
      } catch (err) {
        console.log(err)
        return false
      }
    },

    appIsOffline() {
      console.log('iss off')
      this.isOnline = false
    },

    appIsOnline() {
      this.isOnline = true

      // Submit offline transactions
      const posStore = usePosStore()
      if (posStore.offlineTransactions.length > 0) {
        const transactions = posStore.offlineTransactions
        transactions.forEach((cartData) => {
          api.submitCashTransaction(cartData)
        })

        posStore.clearPendingTransaction()
      }
    },
  },

  getters: {},
})

function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}
