<template>
  <div class="select-none h-full">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <!--
    <main-tabs v-if="$route.name.indexOf('Detail') < 0 && $route.name.indexOf('POS') < 0 && $route.name.indexOf('Login') < 0 && $route.name.indexOf('ModePicker') < 0"/>-->
  </div>
</template>

<script>
import { SplashScreen } from '@capacitor/splash-screen'
import MainTabs from '@/components/admin/MainTabs.vue'

import { useAdminStore } from './store/pinia/admin'

export default {
  name: 'App',
  components: {
    MainTabs,
  },
  setup() {
    // getCSRF();
    const adminStore = useAdminStore()
    return {
      adminStore,
    }
  },
  data() {
    setTimeout(() => {
      SplashScreen.hide()
    }, 2000)

    if (this.$route.name.indexOf('POS') < 0 && this.$route.name.indexOf('Login') < 0 && this.$route.name.indexOf('ModePicker') < 0) {
      this.adminStore.setupAdmin()
    }

    return {}
  },
}
</script>

<style></style>
