import { defineStore } from 'pinia'
import api from '../api'
import Dinero from 'dinero.js'
import { usePosStore } from './pos'
import { v4 as uuidv4 } from 'uuid'
import { useAppStore } from './app'
import moment from 'moment/moment'
import { useToast } from 'vue-toastification'
import { SuccessState, TransactionOptions } from '@mx51/spi-client-js'

const tax = 0.1

// Round a number to 2 decimal places
function round(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

// An empty cart object to be used as a template
export const emptyCart = {
  cart_id: null,
  status: 'PENDING',
  booking_id: null,
  checkin_id: null,
  customer_id: null,
  customer: null,
  sub_total: 0,
  discount_amount: 0,
  reward_amount: 0,
  tax: 0,
  surcharge: 0,
  grand_total: 0,
  promotion: null,
  grand_total_by_card: 0,
  temporary_transaction_number: null,
  items: [],
  reward: null,
  loading: false,
  error: null,
  displayText: null,
  displayReceipt: null,
  displayMerchantReceipt: null,
  tyroBox: {
    msg: null,
    buttons: null,
  },
  mx51Box: {
    status: null,
    isFinished: false,
    displayText: 'In Progress',
    displayMessage: null,
    displayMerchantReceipt: null,
    displayCustomerReceipt: null,
    transaction: null,
  },
  transaction: null,
}

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: {
      cart_id: uuidv4(),
      status: 'PENDING',
      sub_total: 0,
      discount_amount: 0,
      reward_amount: 0,
      tax: 0,
      surcharge: 0,
      grand_total: 0,
      promotion: null,
      grand_total_by_card: 0,
      items: [],
      booking_id: null,
      checkin_id: null,
      customer_id: null,
      customer: null,
      reward: null,
      loading: false,
      error: null,
      displayText: null,
      displayReceipt: null,
      displayMerchantReceipt: null,
      tyroBox: {
        msg: null,
        buttons: null,
      },
      mx51Box: {
        status: null,
        isFinished: false,
        displayText: 'In Progress',
        displayMessage: null,
        displayMerchantReceipt: null,
        displayCustomerReceipt: null,
        transaction: null,
      },
      transaction: null,
    },
    hasGiftCardInCart: false,
    onHold: [],
  }),

  actions: {
    selectPaymentMethod(method) {
      const posStore = usePosStore()
      posStore.paymentFlow = method
      if (method === 'SQUAREUP_CREDIT_CARD_PROCESSING') {
        this.submitSquareTerminalPayment()
      } else if (method === 'MORE_PAYMENTS_VIA_LINKLY') {
        this.submitLinklyPayment()
      } else if (method === 'PAYMENTS_VIA_TYRO') {
        this.submitTyroPurchase({ payment_method: 'PAYMENTS_VIA_TYRO' })
      } else if (method === 'PAYMENTS_VIA_MX51') {
        this.submitMx51Purchase({ payment_method: 'PAYMENTS_VIA_MX51' })
      }
    },

    async updateCartAmounts(data) {
      let sub_total = Dinero({ amount: this.cart.sub_total }).add(Dinero({ amount: data.unit_price_ex_tax })) // this.cart.sub_total + unit_price_ex_tax;
      this.cart.sub_total = sub_total.getAmount()

      let discount_amount = Dinero({ amount: this.cart.discount_amount }).add(Dinero({ amount: data.discount_amount_ex_tax })) // this.cart.discount_amount + discount_amount_ex_tax;
      this.cart.discount_amount = discount_amount.getAmount()

      if (this.cart.reward && this.cart.sub_total >= 2000) {
        this.cart.reward_amount = Dinero({
          amount: this.cart.reward.value,
        }).getAmount()
      }

      if (this.cart.promotion && this.cart.promotion.type) {
        if (this.cart.promotion.type === 'FIXED_AMOUNT') {
          this.cart.discount_amount = Dinero({
            amount: this.cart.promotion.value,
          }).getAmount()
        } else if (this.cart.promotion.type === 'PERCENTAGE') {
          this.cart.discount_amount = Dinero({ amount: this.cart.sub_total })
            .add(Dinero({ amount: this.cart.tax }))
            .subtract(Dinero({ amount: this.cart.reward_amount }))
            .percentage(this.cart.promotion.value)
            .getAmount()
        }
      }

      let tax = Dinero({ amount: this.cart.tax }).add(Dinero({ amount: data.tax_amount })) // this.cart.tax + tax_amount
      this.cart.tax = tax.getAmount()
    },

    async updateCartGrandTotal() {
      let grand_total = Dinero({ amount: this.cart.sub_total })
        .add(Dinero({ amount: this.cart.tax }))
        .subtract(Dinero({ amount: this.cart.reward_amount }))
        .subtract(Dinero({ amount: this.cart.discount_amount })) // this.cart.sub_total + this.cart.tax - this.cart.discount_amount

      if (grand_total.getAmount() < 0) {
        grand_total = Dinero({ amount: 0 })
      }

      this.cart.grand_total = grand_total.getAmount()
    },

    async addSurcharge() {
      let posStore = usePosStore()
      let findSurchargeSetting = posStore.settings.find((setting) => setting.name === 'TRANSACTION_SURCHAGE')
      let findSurchargeRateSetting = posStore.settings.find((setting) => setting.name === 'TRANSACTION_FEE')
      let grand_total = Dinero({ amount: this.cart.grand_total })
      if (findSurchargeSetting && findSurchargeRateSetting && findSurchargeSetting.value === true) {
        let rate = findSurchargeRateSetting.value
        const rateType = rate.indexOf('%') >= 0 ? 'PERCENTAGE' : 'NUMBER'
        if (rateType === 'PERCENTAGE') {
          rate = parseFloat(rate.replace('%', ''))
          this.cart.surcharge = grand_total.percentage(rate).getAmount()
          grand_total = grand_total.add(grand_total.percentage(rate))
        } else {
          this.cart.surcharge = Dinero({ amount: parseInt(rate) }).getAmount()
          grand_total = grand_total.add(Dinero({ amount: parseInt(rate) }))
        }
      }
      this.cart.grand_total_by_card = grand_total.getAmount()
    },

    saveCurrentCart() {
      let cartIndex = this.onHold.findIndex((cart) => cart.cart_id === this.cart_id)
      if (cartIndex >= 0) {
        this.onHold[cartIndex] = Object.assign({}, this.cart)
      } else {
        this.onHold.push(Object.assign({}, this.cart))
      }
      this.clearCart()
    },

    restoreCart(cart_id) {
      let cartIndex = this.onHold.findIndex((cart) => cart.cart_id === cart_id)
      if (cartIndex >= 0) {
        this.cart = Object.assign({}, this.onHold[cartIndex])
        this.onHold.splice(cartIndex, 1)
      }
    },

    async addItemToCart(item) {
      let base_price = Dinero({ amount: item.price.amount })
      let tax_amount, base_price_ex_tax, discount_amount_ex_tax, discount_tax_amount
      let discount_amount = item.discount_price ? base_price.subtract(Dinero({ amount: item.discount_price })) : Dinero({ amount: 0.0 })

      if (item.tax_included) {
        base_price_ex_tax = base_price.divide(1 + tax) //round(base_price / (1 + tax), 2);
        tax_amount = base_price.subtract(base_price_ex_tax) //round(base_price - base_price_ex_tax, 2);
        discount_amount_ex_tax = discount_amount.divide(1 + tax) //round(discount_amount / (1 + tax), 2);
        discount_tax_amount = discount_amount_ex_tax.multiply(tax) //round(discount_amount_ex_tax * tax, 2);
        tax_amount = tax_amount.subtract(discount_tax_amount)
        base_price_ex_tax = base_price_ex_tax.subtract(discount_amount_ex_tax)
      } else {
        base_price_ex_tax = base_price
        tax_amount = round(base_price * tax, 2)
        base_price = base_price_ex_tax + tax_amount
        discount_amount_ex_tax = discount_amount
        discount_tax_amount = round(discount_amount_ex_tax * tax, 2)
        discount_amount = discount_amount_ex_tax + discount_tax_amount
        tax_amount = tax_amount - discount_tax_amount
        base_price_ex_tax = base_price_ex_tax - discount_amount_ex_tax
      }

      this.cart.items = [
        ...this.cart.items,
        Object.assign(
          {
            quantity: 1,
            base_price_ex_tax: base_price_ex_tax.getAmount(),
            base_price: base_price.getAmount(), // price_inc_tax / (1 + tax)
            discount_amount_ex_tax: discount_amount_ex_tax.getAmount(),
            discount_amount: discount_amount.getAmount(),
            tax_amount: tax_amount.getAmount(),
          },
          item
        ),
      ]

      await this.updateCartAmounts({
        unit_price_ex_tax: base_price_ex_tax.getAmount(),
        discount_amount_ex_tax: discount_amount.getAmount(),
        tax_amount: tax_amount.getAmount(),
      })

      if (this.cart.promotion) {
        await this.applyDiscountToCart(this.cart.promotion)
      } else {
        await this.updateCartGrandTotal()
      }
      await this.addSurcharge()
    },

    async applyDiscountToCart(discount) {
      this.cart.promotion = Object.assign({}, discount)
      if (this.cart.reward_amount > 0 && this.cart.sub_total >= 2000) {
        this.cart.promotion.royalty = this.cart.reward
      }

      if (this.cart.items.length > 0) {
        await this.updateCartAmounts({
          unit_price_ex_tax: 0,
          discount_amount_ex_tax: 0,
          tax_amount: 0,
        })
        await this.updateCartGrandTotal()
        await this.addSurcharge()
      }
    },

    async removeItemFromCart(cart_id) {
      let item = this.cart.items.find((item) => item.cart_id === cart_id)

      let base_price = Dinero({
        amount: item.price.type === 'FIXED_PRICING' ? item.price.amount : item.base_price,
      })
      let tax_amount, base_price_ex_tax, discount_amount_ex_tax, discount_tax_amount
      let discount_amount = item.discount_price ? base_price.subtract(Dinero({ amount: item.discount_price })) : Dinero({ amount: 0.0 })

      if (item.tax_included) {
        base_price_ex_tax = base_price.divide(1 + tax) //round(base_price / (1 + tax), 2);
        tax_amount = base_price.subtract(base_price_ex_tax) //round(base_price - base_price_ex_tax, 2);
        discount_amount_ex_tax = discount_amount.divide(1 + tax) //round(discount_amount / (1 + tax), 2);
        discount_tax_amount = discount_amount_ex_tax.multiply(tax) //round(discount_amount_ex_tax * tax, 2);
        tax_amount = tax_amount.subtract(discount_tax_amount)
        base_price_ex_tax = base_price_ex_tax.subtract(discount_amount_ex_tax)
      } else {
        base_price_ex_tax = base_price
        tax_amount = round(base_price * tax, 2)
        base_price = base_price_ex_tax + tax_amount
        discount_amount_ex_tax = discount_amount
        discount_tax_amount = round(discount_amount_ex_tax * tax, 2)
        discount_amount = discount_amount_ex_tax + discount_tax_amount
        tax_amount = tax_amount - discount_tax_amount
        base_price_ex_tax = base_price_ex_tax - discount_amount_ex_tax
      }

      const findIndex = this.cart.items.findIndex((item) => item.cart_id === cart_id)
      if (findIndex >= 0) {
        this.cart.items.splice(findIndex, 1)
      }

      await this.updateCartAmounts({
        unit_price_ex_tax: -base_price_ex_tax.getAmount(),
        discount_amount_ex_tax: -discount_amount.getAmount(),
        tax_amount: -tax_amount.getAmount(),
      })

      if (this.cart.promotion) {
        await this.applyDiscountToCart(this.cart.promotion)
      } else {
        await this.updateCartGrandTotal()
      }
      await this.addSurcharge()

      if (item.name === 'Gift Certificate Redemption') {
        this.hasGiftCardInCart = false
      }
    },

    async submitSquareTerminalPayment(resubmit) {
      const self = this
      const posStore = usePosStore()
      this.cart.displayText = null
      this.cart.status = 'PENDING'
      const currency_code = posStore.settings.find((setting) => setting.name === 'CURRENCY_CODE').value

      const cart_id = resubmit ? uuidv4() : this.cart.cart_id
      let cartData = {
        cart_id: cart_id,
        type: 'PURCHASE',
        note: this.cart.note,
        customer_id: this.cart.customer ? this.cart.customer.id : null,
        booking_id: this.cart.booking_id ?? null,
        checkin_id: this.cart.checkin_id ?? null,
        device_id: this.cart.device_id ?? null,
        items: this.cart.items,
        promotion: this.cart.promotion,
        checkout: {
          payment_method: 'SQUAREUP_CREDIT_CARD_PROCESSING',
          discount_amount: this.cart.discount_amount,
          surcharge: this.cart.surcharge,
          sub_total: this.cart.sub_total,
          tax_amount: this.cart.tax,
          grand_total: this.cart.grand_total_by_card,
          currency_code: currency_code,
        },
      }

      const result = await api.submitCashTransaction(cartData)
      if (result.transaction_number) {
        this.cart.temporary_transaction_number = result.transaction_number
        window.Echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + window.$cookies.get('jwt')
        window.Echo.connector.options.auth.headers['X-REF-ID'] = window.$cookies.get('jwt_ref_id')
        window.Echo.private(`transaction.${result.transaction_number}`).listen('TransactionUpdated', (e) => {
          console.log('Transaction Updated', e)
          self.cart.status = e.transaction.status
          if (e.transaction.status === 'CANCELED' || e.transaction.status === 'COMPLETED' || e.transaction.status === 'CANCEL_REQUESTED') {
            window.Echo.private(`transaction.${result.transaction_number}`).stopListening('TransactionUpdated')
            window.Echo.leaveChannel(`transaction.${result.transaction_number}`)
            if (e.transaction.status === 'COMPLETED') {
              self.selectPaymentMethod('THANK_YOU')
            }
          }
        })
      }
    },

    async submitLinklyPayment(resubmit) {
      const self = this
      const posStore = usePosStore()
      this.cart.displayText = null
      this.cart.status = 'PENDING'
      const currency_code = posStore.settings.find((setting) => setting.name === 'CURRENCY_CODE').value

      const cart_id = resubmit ? uuidv4() : this.cart.cart_id
      let cartData = {
        cart_id: cart_id,
        type: 'PURCHASE',
        note: this.cart.note,
        customer_id: this.cart.customer ? this.cart.customer.id : null,
        booking_id: this.cart.booking_id ?? null,
        checkin_id: this.cart.checkin_id ?? null,
        device_id: this.cart.device_id ?? null,
        items: this.cart.items,
        promotion: this.cart.promotion,
        checkout: {
          payment_method: 'MORE_PAYMENTS_VIA_LINKLY',
          discount_amount: this.cart.discount_amount,
          surcharge: this.cart.surcharge,
          sub_total: this.cart.sub_total,
          tax_amount: this.cart.tax,
          grand_total: this.cart.grand_total_by_card,
          currency_code: currency_code,
        },
      }

      const result = await api.submitCashTransaction(cartData)

      if (result.transaction_number) {
        this.cart.temporary_transaction_number = result.transaction_number
        window.Echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + window.$cookies.get('jwt')
        window.Echo.connector.options.auth.headers['X-REF-ID'] = window.$cookies.get('jwt_ref_id')
        window.Echo.private(`transaction.${result.transaction_number}`).listen('TransactionUpdated', (e) => {
          console.log('Transaction Updated', e)
          self.cart.status = e.transaction.status
          if (
            e.transaction.status === 'FAILED' ||
            e.transaction.status === 'CANCELED' ||
            e.transaction.status === 'COMPLETED' ||
            e.transaction.status === 'CANCEL_REQUESTED'
          ) {
            window.Echo.private(`transaction.${result.transaction_number}`).stopListening('TransactionUpdated')
            window.Echo.leaveChannel(`transaction.${result.transaction_number}`)

            if (e.transaction.status) {
              self.selectPaymentMethod('THANK_YOU')
            }
          }

          if (e.displayTexts) {
            if (e.displayTexts[0] === 'DECLINED') {
              self.cart.status = 'CARD_DECLINED'
              window.Echo.private(`transaction.${result.transaction_number}`).stopListening('TransactionUpdated')
              window.Echo.leaveChannel(`transaction.${result.transaction_number}`)
              self.cart.displayText = e.displayTexts[0]
            } else if (e.displayTexts[0] === 'OPERATOR TIMEOUT') {
              self.cart.status = 'FAILED'
              window.Echo.private(`transaction.${result.transaction_number}`).stopListening('TransactionUpdated')
              window.Echo.leaveChannel(`transaction.${result.transaction_number}`)
            } else {
              self.cart.displayText = e.displayTexts[0]
              if (e.displayTexts[1] && e.displayTexts[1] === 'PLEASE SIGN RECEIPT') {
                self.cart.displayText = 'PLEASE SIGN RECEIPT'
              } else if (self.cart.displayText === 'SIGNATURE OK?') {
                self.cart.displayText = 'VERIFY SIGNATURE'
              }
            }
          }
        })
      } else {
        if (result.message == 'EFT-CLIENT OFFLINE') {
          this.cart.displayText = 'Eftpos terminal is OFFLINE, please reboot the eftpos terminal'
        } else if (result.message) {
          this.cart.displayText = result.message
        }
        this.cart.status = 'FAILED'
      }
    },

    async submitCashTransaction(payload) {
      const appStore = useAppStore()
      const posStore = usePosStore()
      const cart_id = this.cart.cart_id ? this.cart.cart_id : uuidv4()

      const currency_code = posStore.settings.find((setting) => setting.name === 'CURRENCY_CODE').value
      let cartData = {
        cart_id: cart_id,
        type: 'PURCHASE',
        note: payload.note,
        customer_id: this.cart.customer ? this.cart.customer.id : null,
        booking_id: this.cart.booking_id ?? null,
        checkin_id: this.cart.checkin_id ?? null,
        device_id: this.cart.device_id ?? null,
        items: this.cart.items,
        promotion: this.cart.promotion,
        checkout: {
          payment_method: payload.payment_method,
          discount_amount: this.cart.discount_amount,
          surcharge: 0,
          sub_total: this.cart.sub_total,
          tax_amount: this.cart.tax,
          grand_total: this.cart.grand_total,
          currency_code: currency_code,
        },
      }

      if (appStore.isOnline) {
        const result = await api.submitCashTransaction(cartData)
        if (result.transaction_number) {
          this.cart.status = 'COMPLETED'
          this.cart.temporary_transaction_number = result.transaction_number
          this.cart.status = result.status
        }
      } else {
        this.cart.status = 'COMPLETED'
        cartData.offline_created_at = moment().toISOString()
        posStore.addOfflineTransactions(cartData)
      }
    },

    async submitEftposTransaction(payload) {
      const posStore = usePosStore()
      this.cart.status = 'COMPLETED'

      const currency_code = posStore.settings.find((setting) => setting.name === 'CURRENCY_CODE').value
      let cartData = {
        note: payload.note,
        customer: this.cart.customer ? { _id: this.cart.customer._id } : null,
        items: this.cart.items,
        promotion: this.cart.promotion,
        checkout: {
          payment_method: payload.payment_method,
          discount_amount: this.cart.discount_amount,
          surcharge: this.cart.surcharge,
          sub_total: this.cart.sub_total,
          tax_amount: this.cart.tax,
          grand_total: this.cart.grand_total_by_card,
          currency_code: currency_code,
        },
      }

      const result = await api.submitCashTransaction(cartData)
      if (result.transaction_number) {
        this.cart.temporary_transaction_number = result.transaction_number
        this.cart.status = result.status
      }
    },

    async cancelSquareTerminalPayment() {
      let cartData = {
        transaction_number: this.cart.temporary_transaction_number,
      }
      await api.cancelSquareTerminalPayment(cartData)
      this.cart.status = 'PENDING'
    },

    async cancelLinklyPayment() {
      let cartData = {
        transaction_number: this.cart.temporary_transaction_number,
      }
      await api.cancelLinklyPayment(cartData)
      this.cart.status = 'PENDING'
    },

    async sendReceipt(data) {
      data.transaction_number = this.cart.temporary_transaction_number
      const result = await api.sendReceipt(data)
      //if (result.status === 'ok') {
        const toast = useToast()
        toast.success('Receipt has been sent to the customer', {
          timeout: 2000,
        })
      //}
      return true
    },

    async submitLinklyYes() {
      this.cart.displayText = null
      await api.sendLinklyKey({
        key: 'YES',
        transaction_number: this.cart.temporary_transaction_number,
      })
    },

    async submitLinklyYesAndCompleteSale() {
      this.cart.displayText = null
      await api.sendLinklyKey({
        key: 'YES',
        transaction_number: this.cart.temporary_transaction_number,
      })
      this.cart.status = 'COMPLETED'

      window.Echo.private(`transaction.${this.cart.temporary_transaction_number}`).stopListening('TransactionUpdated')
      window.Echo.leaveChannel(`transaction.${this.cart.temporary_transaction_number}`)
      this.selectPaymentMethod('THANK_YOU')
    },

    async submitLinklyNo() {
      this.cart.displayText = null
      await api.sendLinklyKey({
        key: 'NO',
        transaction_number: this.cart.temporary_transaction_number,
      })
    },

    async submitLinklyNoAndCompleteSale() {
      this.cart.displayText = null
      await api.sendLinklyKey({
        key: 'NO',
        transaction_number: this.cart.temporary_transaction_number,
      })
      this.cart.status = 'COMPLETED'

      window.Echo.private(`transaction.${this.cart.temporary_transaction_number}`).stopListening('TransactionUpdated')
      window.Echo.leaveChannel(`transaction.${this.cart.temporary_transaction_number}`)
      this.selectPaymentMethod('THANK_YOU')
    },

    async selectCheckIn(checkIn) {
      const result = await api.getCustomerByCheckIn(checkIn)
      this.cart.booking_id = checkIn.booking_id
      this.cart.checkin_id = checkIn.id
      this.cart.customer_id = result.id
      this.cart.customer = result
      if (checkIn.customer.next_reward.reward) {
        this.cart.reward = checkIn.customer.next_reward.reward ?? null
        this.cart.reward.visit_count = checkIn.customer.next_reward.rule.visit_count
        this.cart.promotion = Object.assign({}, this.cart.promotion)
        this.cart.promotion.royalty = this.cart.reward
      }

      this.updateCartAmounts({
        unit_price_ex_tax: 0,
        discount_amount_ex_tax: 0,
        tax_amount: 0,
      })
      this.updateCartGrandTotal()
      this.addSurcharge()
    },

    /* TYRO */
    submitTyroPurchase(payload) {
      const self = this
      self.cart.tyroBox.msg = null
      const appStore = useAppStore()
      const posStore = usePosStore()
      const cart_id = this.cart.cart_id ? this.cart.cart_id : uuidv4()

      const currency_code = posStore.settings.find((setting) => setting.name === 'CURRENCY_CODE').value
      const surchargeEnabled = this.surchargeEnabled
      let cartData = {
        cart_id: cart_id,
        type: 'PURCHASE',
        note: payload.note,
        customer_id: this.cart.customer ? this.cart.customer.id : null,
        booking_id: this.cart.booking_id ?? null,
        checkin_id: this.cart.checkin_id ?? null,
        device_id: this.cart.device_id ?? null,
        items: this.cart.items,
        promotion: this.cart.promotion,
        checkout: {
          payment_method: payload.payment_method,
          discount_amount: this.cart.discount_amount,
          surcharge: this.cart.surcharge,
          sub_total: this.cart.sub_total,
          tax_amount: this.cart.tax,
          grand_total: this.cart.grand_total,
          currency_code: currency_code,
        },
      }

      var requestParams = {
        amount: cartData.checkout.grand_total.toString(),
        integratedReceipt: posStore.tyroBox.settings.enableIntegratedReceipt,
        enableSurcharge: surchargeEnabled,
        mid: posStore.tyroBox.pairingConfig.mid,
        tid: posStore.tyroBox.pairingConfig.tid,
        integrationKey: posStore.tyroBox.pairingConfig.integrationKey,
      }
      console.log(requestParams)
      let merchantReceipt = null
      try {
        //window.iclient.continueLastTransaction();
        window.iclient.initiatePurchase(
          requestParams,
          {
            statusMessageCallback: function (status) {
              console.log(status)
              self.cart.displayText = status
            },
            questionCallback: function (question, answerCallback) {
              var buttons = question.options.map(function (option) {
                return {
                  label: option,
                  callback: function () {
                    answerCallback(option)
                  },
                }
              })
              console.log(question, buttons)
              if (question.isError) {
                self.cart.displayText = 'ERROR'
              }
              self.cart.tyroBox.msg = question.text
              self.cart.tyroBox.buttons = buttons
            },
            receiptCallback: function (receipt) {
              console.log(receipt)
              merchantReceipt = receipt.merchantReceipt
              if (receipt.signatureRequired) {
                self.cart.displayText = 'CONFIRM_SIGNATURE'
              }
              self.cart.displayMerchantReceipt = "<pre class='bg-gray-100 p-4 rounded'>" + receipt.merchantReceipt + '</pre>'
            },
            transactionCompleteCallback: function (transactionData) {
              console.log(transactionData)
              var message = ''
              if (transactionData.customerReceipt) {
                message += "<pre  class='bg-gray-100 p-4 rounded'>" + transactionData.customerReceipt + '</pre>'
                self.cart.displayReceipt = message
              }

              if (transactionData.result !== 'NOT STARTED' && transactionData.result !== 'UNKNOWN') {
                console.log(message)
                transactionData.merchantReceipt = merchantReceipt
                cartData.result = transactionData
                if (
                  transactionData.result == 'DECLINED' ||
                  transactionData.result == 'REVERSED' ||
                  transactionData.result == 'CANCELLED' ||
                  transactionData.result == 'SYSTEM ERROR'
                ) {
                  cartData.status = 'FAILED'
                }

                if (transactionData.result != 'CANCELLED') {
                  cartData.checkout.surcharge = parseInt(parseFloat(transactionData.surchargeAmount) * 100)
                  cartData.checkout.grand_total = parseInt(parseFloat(transactionData.baseAmount) * 100)
                  //self.submitCashTransaction(payload)
                  api.submitCashTransaction(cartData).then((result) => {
                    if (result.transaction_number) {
                      self.cart.transaction = result
                      self.cart.temporary_transaction_number = result.transaction_number
                      self.cart.status = result.status
                    }
                  })
                }

                self.cart.displayText = transactionData.result
              } else if (transactionData.result == 'DECLINED') {
                self.cart.displayText = transactionData.result
              } else {
                self.cart.displayText = 'CANCELED'
                const posStore = usePosStore()
                posStore.paymentFlow = 'PAYMENT_METHODS'
              }
              self.cart.tyroBox.msg = null
              self.cart.tyroBox.buttons = null
            },
          },
          false
        )
      } catch (err) {
        console.log(err)
        if (err.toString().indexOf('Transaction already in progress')) {
          console.log('cancel')
          window.iclient.continueLastTransaction({
            questionCallback: function () {
              console.log('questionCallback')
            },
            statusMessageCallback: function () {
              console.log('statusMessageCallback')
            },
            receiptCallback: function () {
              console.log('receiptCallback')
            },
            transactionCompleteCallback: function () {
              console.log('transactionCompleteCallback')
            },
          })
        }
      }
    },

    cancelTyroPurchase() {
      try {
        window.iclient.cancelCurrentTransaction()
      } catch (err) {
        console.log(err)
        const posStore = usePosStore()
        posStore.paymentFlow = 'PAYMENT_METHODS'
      }
    },

    /* END of TYRO */

    /* MX 51 */
    submitMx51Purchase(payload) {
      const self = this
      self.cart.status = 'PENDING'
      self.cart.mx51Box.status = 'IN_PROGRESS'
      self.cart.mx51Box.displayText = 'In Progress'
      self.cart.mx51Box.displayMessage = ''
      self.cart.mx51Box.displayMerchantReceipt = null
      self.cart.mx51Box.displayCustomerReceipt = null
      self.cart.mx51Box.isFinished = false
      const posStore = usePosStore()
      window.spi.AckFlowEndedAndBackToIdle()
      var options = new TransactionOptions()
      options.SetCustomerReceiptHeader(posStore.mx51Box.settings.posVendorId + ' ' + posStore.mx51Box.settings.posVersion)
      options.SetMerchantReceiptHeader(posStore.mx51Box.settings.posVendorId + ' ' + posStore.mx51Box.settings.posVersion)
      const refId = (this.cart.cart_id = uuidv4())
      window.spi.InitiatePurchaseTxV2(
        refId,
        this.cart.surcharge ? this.cart.grand_total_by_card - this.cart.surcharge : this.cart.grand_total_by_card,
        0,
        0,
        false,
        options,
        this.cart.surcharge
      )
    },

    async completeMx51Purchase(payload) {
      const appStore = useAppStore()
      const posStore = usePosStore()
      const cart_id = this.cart.cart_id ? this.cart.cart_id : uuidv4()

      const currency_code = posStore.settings.find((setting) => setting.name === 'CURRENCY_CODE').value
      let cartData = {
        cart_id: cart_id,
        type: 'PURCHASE',
        note: payload.note ?? null,
        customer_id: this.cart.customer ? this.cart.customer.id : null,
        booking_id: this.cart.booking_id ?? null,
        checkin_id: this.cart.checkin_id ?? null,
        device_id: this.cart.device_id ?? null,
        items: this.cart.items,
        promotion: this.cart.promotion,
        checkout: {
          payment_method: payload.payment_method,
          discount_amount: this.cart.discount_amount,
          surcharge: this.cart.surcharge,
          sub_total: this.cart.sub_total,
          tax_amount: this.cart.tax,
          grand_total: this.cart.grand_total_by_card,
          currency_code: currency_code,
        },
        result: payload.result,
      }

      if (appStore.isOnline) {
        const result = await api.submitCashTransaction(cartData)
        if (result.transaction_number) {
          this.cart.transaction = result
          this.cart.status = 'COMPLETED'
          this.cart.temporary_transaction_number = result.transaction_number
          this.cart.status = result.status
        }
      } else {
        this.cart.status = 'COMPLETED'
        cartData.offline_created_at = moment().toISOString()
        posStore.addOfflineTransactions(cartData)
      }
    },

    setMx51Messages(messages) {
      Object.keys(messages).map((key) => {
        this.cart.mx51Box[key] = messages[key]
      })
    },

    cancelMx51Purchase() {
      const posStore = usePosStore()
      if (
        this.cart.mx51Box.status === 'DECLINED' ||
        this.cart.mx51Box.status === 'FAILED' ||
        this.cart.mx51Box.status === 'CANCELED' ||
        this.cart.mx51Box.status === 'OVERRIDE_DECLINED'
      ) {
        posStore.paymentFlow = 'PAYMENT_METHODS'
        this.cart.mx51Box.isFinished = true
        window.spi.AckFlowEndedAndBackToIdle()
        return
      }

      const appStore = useAppStore()
      if (!appStore.isOnline || posStore.mx51Box.pairingStatus === 'PairedConnecting') {
        this.setMx51Messages({
          displayText: 'Attempting to cancel...',
        })

        setTimeout(() => {
          try {
            if (window.spi.CurrentTxFlowState?.Success === SuccessState.Unknown) {
              this.setMx51Messages({
                status: 'UNKNOWN',
                displayText: 'Transaction Unknown',
              })
            }
          } catch (err) {
            console.log(err)
          }
        }, 3000)
      } else {
        window.spi.CancelTransaction()
        this.cart.mx51Box.isFinished = true
        posStore.paymentFlow = 'PAYMENT_METHODS'
      }
    },

    successfullyCancelMx51Purchase() {
      const posStore = usePosStore()
      posStore.paymentFlow = 'PAYMENT_METHODS'
    },

    cancelMx51Refund() {
      const posStore = usePosStore()
      const appStore = useAppStore()

      if (
        this.cart.mx51Box.status === 'REFUND_DECLINED' ||
        this.cart.mx51Box.status === 'REFUND_FAILED' ||
        this.cart.mx51Box.status === 'REFUND_CANCELED' ||
        this.cart.mx51Box.status === 'REFUND_OVERRIDE_APPROVED' ||
        this.cart.mx51Box.status === 'REFUND_OVERRIDE_DECLINED'
      ) {
        this.setMx51Messages({
          status: null,
          isFinished: true,
          displayText: 'In Progress',
          displayMessage: null,
          displayMerchantReceipt: null,
          displayCustomerReceipt: null,
        })
        return
      }

      if (!appStore.isOnline) {
        this.setMx51Messages({
          displayText: 'Attempting to cancel...',
        })
        try {
          if (window.spi.CurrentTxFlowState?.Success === SuccessState.Unknown) {
            this.setMx51Messages({
              status: 'REFUND_UNKNOWN',
              displayText: 'Transaction Unknown',
            })
          }
          /*
          if (posStore.mx51Box.pairingStatus.status === 'PAIRED') {
            this.setMx51Messages({
              status: null,
              displayText: 'In Progress',
              displayMessage: null,
            })
          }*/
        } catch (err) {
          console.log(err)
        }
      } else {
        this.setMx51Messages({
          displayText: 'Attempting to cancel...',
        })
        window.spi.CancelTransaction()
      }
    },
    /* END of MX 51 */
    async validateCheckoutGiftCertificateBarcode(barcode) {
      return await api.validateCheckoutGiftCertificateBarcode(barcode)
    },

    updateCartCustomer(customer) {
      this.cart.customer = customer
    },

    clearCart() {
      const posStore = usePosStore()
      const onHoldCarts = [...this.onHoldCarts]
      this.$reset()
      this.cart.cart_id = uuidv4()
      this.onHold = onHoldCarts
      posStore.paymentFlow = 'PAYMENT_METHODS'
    },

    recoverCart() {
      console.log('recovering cart')
    },
  },

  getters: {
    cartCustomer: (state) => state.cart.customer,
    onHoldCarts: (state) => state.onHold,
    cartSubtotal: (state) => state.cart.sub_total,
    cartHasDiscount: (state) => state.cart.discount_amount > 0,
    cartDiscount: (state) => state.cart.discount_amount,
    cartTax: (state) => state.cart.tax,
    cartGrandTotalByCard: (state) => state.cart.grand_total_by_card,
    cartGrandTotal: (state) => state.cart.grand_total,
    cartSurcharge: (state) => state.cart.surcharge,
    cartStatus: (state) => state.cart.status,
    surchargeEnabled: () => {
      const posStore = usePosStore()
      const findSurchargeSetting = posStore.settings.find((setting) => setting.name === 'TRANSACTION_SURCHAGE')
      return findSurchargeSetting && findSurchargeSetting.value === true
    },
  },
})
