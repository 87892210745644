const Login = () => import('./screens/auth/Login.vue')
const PosLogin = () => import('./screens/auth/PosLogin.vue')
const ModePicker = () => import('./screens/misc/ModePicker.vue')

const PosScreen = () => import('./screens/pos/MainScreen.vue')

export const routes = [
  { path: '/', component: Login, name: 'Login' },
  { path: '/pos-login', component: PosLogin, name: 'POS-Login' },
  { path: '/mode', component: ModePicker, name: 'ModePicker' },
  { path: '/pos', component: PosScreen, name: 'POS' },
]
