import { defineStore } from 'pinia'
import api from '../api'
import { useAppStore } from './app'
import { usePosStore } from './pos'
import { useAdminStore } from './admin'
import { useCartStore } from './cart'
import { Device } from '@capacitor/device'

export const useUserStore = defineStore('user', {
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'user',
        storage: localStorage,
      },
    ],
  },

  state: () => ({
    name: null,
    jwt: null,
    email: null,
    company: null,
    updated_at: null,
    accessible_places: [],
  }),

  actions: {
    async login(loginCredentials) {
      const formData = new FormData()
      const $cookies = window.$cookies

      for (const key in loginCredentials) {
        if (key !== 'remember_me') {
          formData.append(key, loginCredentials[key])
        }
      }

      try {
        const result = await api.login(formData)
        if (!result) return false

        this.name = result.name
        this.email = result.email
        this.company = result.company
        // this.jwt = result.jwt
        $cookies.set('jwt', result.jwt)
        $cookies.set('jwt_ref_id', result.tenant.domain)
        $cookies.set('device_id', (await Device.getId()).identifier)
        this.accessible_places = result.accessible_places
        this.updated_at = result.updated_at

        return true
      } catch (err) {
        return false
      }
    },

    async posLogin(loginCredentials) {
      const formData = new FormData()
      const $cookies = window.$cookies

      for (const key in loginCredentials) {
        formData.append(key, loginCredentials[key])
      }

      try {
        const result = await api.posLogin(formData)
        if (!result) return false

        this.name = result.name
        this.email = result.email
        this.company = result.company
        // this.jwt = result.jwt
        $cookies.set('jwt', result.jwt)
        $cookies.set('jwt_ref_id', result.tenant.domain)
        //$cookies.set('device_id', (await Device.getId()).uuid);
        this.accessible_places = result.accessible_places
        this.updated_at = result.updated_at

        return true
      } catch (err) {
        return false
      }
    },

    async checkPasscode(passcode) {
      const check = await api.checkPasscode(passcode)
      return check
    },

    async signOut() {
      const appStore = useAppStore()
      const posStore = usePosStore()
      const adminStore = useAdminStore()
      const cartStore = useCartStore()

      if (window.spi) {
        window.spi.AckFlowEndedAndBackToIdle()
        window.spi._conn.Disconnect()
        window.spi._secrets = null
      }

      await this.$reset()
      await appStore.$reset()
      await adminStore.$reset()
      await posStore.$reset()
      await cartStore.$reset()
      localStorage.clear()

      const $cookies = window.$cookies
      $cookies.remove('jwt')
      $cookies.remove('device_id')
      $cookies.remove('jwt_ref_id')
      $cookies.remove('XSRF-TOKEN')

      window.location.reload()
    },
  },

  getters: {
    isLoggedIn() {
      const $cookies = window.$cookies
      return $cookies.isKey('jwt') //!!this.jwt
    },
  },
})
